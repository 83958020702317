import React, { Component } from 'react';
import styled from 'styled-components';
import { Section, Container } from '@components/global';
import { Link } from 'gatsby';
import axios from 'axios';
const API_URL = '/api/contact/mail.php';

class Contact extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    gdprMessage: 'Hiermit akzeptiere ich die Datenschutzbedingungen',
    gdprCheckboxValue: false,
    honey: '',
    type: '',
    displayMessage: '',
    disableSendButton: false,
  };
  handleNameChange = event => {
    this.setState({ name: event.target.value });
  };
  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };
  handlePhoneChange = event => {
    this.setState({ phone: event.target.value });
  };
  handleSubjectChange = event => {
    this.setState({ subject: event.target.value });
  };
  handleHoneyChange = event => {
    this.setState({ honey: event.target.value });
  };
  handleCheckedChanged = event => {
    this.setState({ gdprCheckboxValue: event.target.checked });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.gdprCheckboxValue === true) {
      const formData = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        gdprMessage: this.state.gdprMessage,
        gdprCheckBoxState: this.state.gdprCheckboxValue,
        honey: this.state.honey,
      };
      this.setState({
        type: 'info',
        displayMessage: 'Wird versendet...',
        disableSendButton: true,
      });

      axios
        .post(API_URL, formData)
        .then(() => {
          this.setState({
            type: 'success',
            displayMessage: 'Email wurde erfolgreich versendet',
          });
        })
        .catch(() => {
          this.setState({
            type: 'danger',
            displayMessage: 'Beim senden der Email ist ein Fehler aufgetreten.',
            disableSendButton: false,
          });
        });
    } else {
      alert('Bitte akzeptieren Sie die Datenschutzbedingungen.');
      this.setState({
        disableSendButton: false,
      });
    }
  };
  render() {
    if (this.state.type && this.state.displayMessage) {
      var classString = 'alert-' + this.state.type;
      var status = (
        <div id="status" className={classString} ref="status">
          {this.state.displayMessage}
        </div>
      );
    }
    return (
      <Section id="contact">
        <StyledContainer>
          <h2>Kontakt</h2>
          <br />
          <br />
          <p>
            Unser Angebot sagt Ihnen zu? <br />
            Kontaktieren Sie uns gerne und wir erstellen gemeinsam ein Konzept
            für Sie!
          </p>
          <Grid>
            <Column>
              <StyledForm onSubmit={this.handleSubmit}>
                <StyledLabel htmlFor="asdfnamefdsa">
                  Name
                  <StyledInput
                    type="text"
                    id="asdfnamefdsa"
                    name="asdfnamefdsa"
                    autoComplete="asfdasf"
                    required="required"
                    onChange={this.handleNameChange}
                  ></StyledInput>
                </StyledLabel>

                <StyledLabel htmlFor="asdfemailfdsa">
                  E-Mail
                  <StyledInput
                    type="text"
                    id="asdfemailfdsa"
                    name="asdfemailfdsa"
                    autoComplete="asfdasdf"
                    required="required"
                    onChange={this.handleEmailChange}
                  ></StyledInput>
                </StyledLabel>

                <StyledLabel htmlFor="asdfphonefdsa">
                  Telefon
                  <StyledInput
                    type="text"
                    id="asdfphonefdsa"
                    name="asdfphonefdsa"
                    autoComplete="asdfafsd"
                    onChange={this.handlePhoneChange}
                  ></StyledInput>
                </StyledLabel>

                <StyledLabel htmlFor="asdfsubjectfdsa">
                  Ihre Nachricht
                  <StyledTextArea
                    id="asdfsubjectfdsa"
                    name="asdfsubjectfdsa"
                    autoComplete="asfdasdf"
                    placeholder="Schreiben Sie uns"
                    required="required"
                    onChange={this.handleSubjectChange}
                  ></StyledTextArea>
                </StyledLabel>
                <CheckboxWrapper>
                  <StyledInput
                    type="checkbox"
                    id="GDPR"
                    name="GDPR"
                    required="required"
                    onClick={this.handleCheckedChanged}
                  ></StyledInput>{' '}
                  <StyledLabel id="GDPRLabel" htmlFor="GDPR">
                    Ich akzeptiere den{' '}
                    <Link to="/datenschutz/">Datenschutz</Link>*.
                  </StyledLabel>
                </CheckboxWrapper>

                <label className="ohnohoney" htmlFor="name">
                  {' '}
                  <input
                    className="ohnohoney"
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="asdfasdf"
                    onChange={this.handleHoneyChange}
                  ></input>
                </label>

                {status}
                {this.state.disableSendButton ? (
                  <StyledButton
                    type="submit"
                    value="Senden"
                    disabled={this.state.disableSendButton}
                    style={{ backgroundColor: 'gray', cursor: 'default' }}
                  />
                ) : (
                  <StyledButton
                    type="submit"
                    value="Senden"
                    disabled={this.state.disableSendButton}
                    style={{ backgroundColor: '#0475bb', cursor: 'pointer' }}
                  />
                )}
              </StyledForm>
            </Column>
            <Column>
              <strong>B&amp;P RAUM-Concept GmbH</strong>
              <p>Erlanger Straße 32</p>
              <p>90765 Fürth</p>
              <br /> <br />
              <p>Telefon: 0911 93163426</p>
              <p>Fax: 0911 93163428</p>
              <p>
                Mobil: 0173 4197364, Reinhold Pepel <br /> 0172 3075930, Uwe
                Böhm
              </p>
              <p>E-Mail: info@bp-raumconcept.de</p>
              <br />
              <br />
              <strong>Unsere Bürozeiten:</strong>
              <p>Mo-Do</p>
              <p>8:00 Uhr - 14:00 Uhr</p>
              <p>Fr</p>
              <p>8:00 Uhr - 12:00 Uhr</p>
              <br />
              <br />
              <br />
              <br />
              <br />
            </Column>
          </Grid>
        </StyledContainer>
      </Section>
    );
  }
}

const Grid = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: 5fr 4fr;
  text-align: left;
  align-items: center;
  justify-items: center;

  h2 {
    margin-bottom: 16px;
    color: ${props => props.theme.color.black.regular};
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;
const Column = styled.div`
  float: left;
  margin-top: 6px;
  padding: 20px;
`;
const CheckboxWrapper = styled.div`
  #GDPR {
    width: 5%;
    display: inline-block;
  }

  #GDPRLabel {
    display: inline;
  }
`;

const StyledForm = styled.form`
  border-radius: 5px;
  padding: 20px;

  .alert-success {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  .alert-info {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  .alert-danger {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .ohnohoney {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
`;

const StyledLabel = styled.label`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 0px;
  background: #eeedee;
  margin-top: 6px;
  margin-bottom: 32px;
  resize: vertical;
  ${props => props.theme.font_size.small};
`;

const StyledButton = styled.input`
  ${props => props.theme.font_size.small};
  margin-top: 16px;
  color: white;
  border-radius: 4px;
  padding: 8px 80px;
  border: none;
  cursor: pointer;
  float: right;
`;

const StyledContainer = styled(Container)`
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  max-width: 995px;
  margin: auto;
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    ${Column}, ${StyledButton} {
      width: 100%;
      margin-top: 0;
    }
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 155px;
  padding: 12px;
  border: 0px;
  background: #eeedee;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: none;
  ${props => props.theme.font_size.small};
`;

export default Contact;
